<template>
  <div class="box">
    <b-icon icon="unlock-alt" custom-size="fa-3x" class="icon-block"></b-icon>
    <h1 class="title has-text-centered">Восстановление пароля</h1>
    <form @submit.prevent="submit">
      <b-field
        label="Email"
        :type="{
          'is-danger': $v.email.$error,
          'is-success': !$v.email.$invalid && $v.email.$dirty,
        }"
        :message="{ 'Некорректно указан email': $v.email.$error }"
      >
        <b-input
          placeholder="example@mail.com"
          :lazy="true"
          v-model.trim="$v.email.$model"
        ></b-input>
      </b-field>
      <div
        class="g-recaptcha"
        data-sitekey="6LdpUEkbAAAAACpMAtqivdSajhIlak-vzqDNMdXT"
      ></div>
      <b-button
        native-type="submit"
        type="is-success"
        expanded
        icon-left="sign-in-alt"
        >Восстановить пароль</b-button
      >
    </form>
    <div class="back-login">
      <h6>
        <b-icon pack="fas" icon="arrow-left" size="is-medium"></b-icon>
        <router-link to="/login">Вернуться на страницу авторизации</router-link>
      </h6>
    </div>
  </div>
</template>

<script>
import { maxLength, required, email } from 'vuelidate/lib/validators';
import API from '@/api';

export default {
  name: 'RestorePassword',
  data() {
    return {
      email: '',
      hasError: true,
    };
  },
  created() {
    const recaptcha = document.createElement('script');
    recaptcha.async = true;
    recaptcha.defer = true;
    recaptcha.src = 'https://www.google.com/recaptcha/api.js';
    document.head.appendChild(recaptcha);

    this.$store.commit('toggleLoader', false);
  },
  methods: {
    async submit() {
      this.$v.$touch();

      /*global grecaptcha*/
      if (grecaptcha.getResponse().length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Пройдите проверку КАПЧИ',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        return;
      }

      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit('toggleLoader', true);

      const data = await API.restorePassword(this.email);

      this.$store.commit('toggleLoader', false);

      if (data.status === 'failed') {
        this.$buefy.toast.open({
          duration: 5000,
          message: data.message,
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        return;
      }

      this.$buefy.toast.open({
        duration: 5000,
        message: data.message,
        position: 'is-bottom-right',
        type: 'is-success',
      });

      setTimeout(function () {
        window.location.href = '/login';
      }, 5000);
      //window.location.href = '/login';
    },
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(40),
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 500px;
  background: #fff;
  position: relative;
  padding: 50px 30px 30px;
  color: #17a2b7;

  .icon-block {
    position: absolute;
    top: 0px;
    transform: translateY(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-shadow: 0 -1px 0px 0px rgb(0 0 0 / 20%);
  }

  h1 {
    font-weight: 500;
  }

  form {
    width: 100%;

    button {
      margin-top: 20px;
    }

    .g-recaptcha {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .back-login {
    margin-top: 20px;

    h6 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        font-weight: bold;
      }
    }
  }
}
</style>
